.spac {
  height: 30px;
}

.rem_border .dz-message {
  border-bottom: 0;
}

.rem_border {
  border: 1px dashed #d2ddec;
  background-color: #ffffff
}

.color_text {
  font-size: 14px;
  text-transform: capitalize;
  color: #95aac9;
}

.btn_cs {
  background: transparent;
  border: 0;
  padding: 5px 15px;
  text-transform: capitalize;
  color: #95aac9;
  margin-bottom: 8px;
  font-size: 14px;
}

.btn_cs:focus {
  color: #000000
}

.btn_cs:hover {
  color: #000000
}

.link_text {
  height: 40px;
  min-width: 300px;
  border: 1px solid #ccc;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  border-right: 0;
  padding: 10px;
}

.link_text:focus {
  outline: 0;
}

.btn_submit {
  border: 1px solid #ccc;
  padding: 8px 20px;
  text-transform: capitalize;
  font-size: 14px;
  background: transparent;
  color: #95aac9;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.btn_submit:focus {
  color: #000000
}

.btn_submit:hover {
  color: #000000
}

.box-height {
  height: 350px;
}

.center-select {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
}

.focus-out:focus {
  color: #12263f;
  background-color: #ffffff;
  border-color: #d2ddec;
  outline: 0;
  box-shadow: none;
}

.dropzone-single .dz-preview {
  margin: 0px !important;
}

.width-auto {
  width: 70px !important;
}

.category-multiple .badge {
  font-size: 100%;
  margin-right: 10px;
}

.back-inp {
  width: 100%;
  height: 100%;
}

.bg-gradient {
  background: linear-gradient(to bottom right,
      rgba(186, 33, 90, 0.9),
      rgba(232, 81, 193, 0.9));
}

.footer-logo {
  filter: brightness(0) invert(1);
}

.text-color {
  color: #ba215a;
}

.CodeMirror {
  height: 550px !important;
}

.dz-preview-multiple .dz-message {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.img-box-s {
  width: 3rem;
  height: 3rem;
  border-radius: 9px;
}

.drop-image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.img-box-s img {
  border-radius: 5px;
}

.transform-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0px;
  transform: translate(-50%, -50%);
  width: 100%;
}



.css-1wa3eu0-placeholder {
  color: #b1c2d9 !important;

}

.css-yk16xz-control {
  border-color: #d2ddec !important;
}

.input-group-append.show-password {
  position: absolute;
  right: 11px;
  top: 12px;
  color: #95aac9;
  z-index: 9;
}

.ql-editor {
  border-top: 1px solid #d2ddec !important;
  border: none;

}

.ql-container.ql-snow {
  border: 1px solid #d2ddec;
}

.ql-toolbar.ql-snow {
  border: 1px solid #d2ddec;
}

.review-content {
  max-width: 300px;
  margin: 0px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.star-icon.filled {
  color: #f7a115;
}

.star-icon {
  color: #333;
  font-size: 20px;
}

.user-review li {
  position: absolute;
  left: 0;
  max-width: 350px;
  top: 100%;
  z-index: 9;
  display: none;
  background: #FFCCAA;
  border: 1px solid #FF3334;
  border-radius: 8px;
  padding: 0.5em 0.5em 0.5em 1em;
  transition: .3s;
}



/* .review-tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 5%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #FFCCAA transparent;
} */

.user-review.hover:hover .review-tooltip {
  display: block;
}

.btn_cr {
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #ba215a;
  border: 0;
  color: #fff;
  transition: all 0.5s;
}

.in_box_size {
  width: 5rem;
  height: 5rem;
}

.btn_cr:hover {
  background: #000;
  transition: all 0.5s;
}

.top-3 {
  top: 2px;
  position: relative;
}

.input_copy {
  border: 1px solid #d2ddec;
  border-radius: 0.375rem;
  background-color: #fff;
}

.border_right {
  border-right: 1px solid #d2ddec !important;
  border-radius: 0;
  background: transparent;
}

.focus_out:focus {
  box-shadow: none;
}

.btn_wid {
  width: 98px !important;
  padding: 10px;
  border-radius: 0;
  background-image: linear-gradient(to bottom right, rgba(186, 33, 90, 0.9), rgba(232, 81, 193, 0.9));
  color: #fff;
  font-weight: 600;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  transition: all 0.55s;
}

.btn_wid:hover {
  background-image: linear-gradient(to bottom right, rgba(232, 81, 193, 0.9), rgba(186, 33, 90, 0.9));
  transition: all 0.5s;
}

.btn_wid:focus {
  box-shadow: none;
  color: #fff;
}

.flatpickr-calendar .flatpickr-day.selected {
  background-color: #ba215a !important;
  border-color: #ba215a !important;

}

.box_img {
  max-width: 86px;
}

.view_btn {
  border: 0;
  padding: 5px 11px;
  height: 30px;
  font-size: 11px;
  border-radius: 0.3rem;
  background: #ba215a;
  color: #fff;
}

.btn_cr.close-img-icon {
  top: -11px;
  right: 2px;
}

.mail-template .CodeMirror {
height: 300px !important;
}

.background-orange {
  background-color: #F63715 !important;
  color: white;
}

.cursorPointer {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* date picker width */
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker__input-container > input {
  width: 100%;
}


.user_profile {
  width:40px;
  height:40px;
  border-radius: 100%;
  overflow:hidden;
  object-fit:cover;
}

.maxWidth200{
  max-width:200px;
}

.modalMediaPreview{
  width:50vw;
}
@media only screen and (max-width: 600px) {
  .modalMediaPreview{
    width:80vw;
  }
}

.min-w-190 {
  min-width: 190px;
}